import React from "react";

const TagButtonItem = ({ data }) => {
  const { tag, handleTagClick } = data;

  return (
    <div className="tag-item">
      <button name={tag} onClick={handleTagClick} className="btn">
        {tag}
      </button>
    </div>
  );
};

export default TagButtonItem;
