const allWorkItems = [
  {
    tags: ["branding", "packaging", "identity"],
    img: "/assets/pages/work/featured-image-curries-kabbabs.jpg",
    title: "Curries and Kababs",
    urlLink: "/view-work",
    detailedWorkData: {
      heroImg:
        "/assets/pages/work/every-works-data/curries-and-kababs/hero.jpg",
      imgWithTextGrid_1_Img:
        "/assets/pages/work/every-works-data/curries-and-kababs/imgWithTextGrid_1_Img.jpg",
      imgWithTextGrid_1_Txt:
        "The clients approached us to create a new identity for themselves. What started as a franchise of Colonel’s Kababz, ended up becoming <strong>Curries and Kababs</strong>!",
      imgWithTextGrid_2_Img:
        "/assets/pages/work/every-works-data/curries-and-kababs/imgWithTextGrid_2_Img.jpg",
      imgWithTextGrid_2_Txt:
        "<strong>Curries and Kababs</strong> came to us wanting to rebrand their brand back in 2016. They had a clear idea of the vibe they wanted associated with their restaurant. So, when developing the visual identity it was important for us to convey and express the brand fully.",
      displayImg_Group_1_Img:
        "/assets/pages/work/every-works-data/curries-and-kababs/displayImg_Group_1_Img.jpg",
      displayImg_Group_1_Txt:
        "<strong>Curries and Kababs</strong> was a restaurant at the heart of Delhi well known for serving mughlai cuisine. What started as a franchise ended up becoming it’s own brand and we had a little something to do with it!",
      displayImg_Group_2_Img:
        "/assets/pages/work/every-works-data/curries-and-kababs/displayImg_Group_2_Img.jpg",
      displayImg_Group_2_Txt:
        "The colour palette and logo is borrowed from truck art popular in South Asia. Each element is part of one big story!",
      displayImg_Grid_Img_1:
        "/assets/pages/work/every-works-data/curries-and-kababs/displayImg_Grid_Img_1.jpg",
      displayImg_Grid_Img_2:
        "/assets/pages/work/every-works-data/curries-and-kababs/displayImg_Grid_Img_2.jpg",
      singleDisplayImg_1:
        "/assets/pages/work/every-works-data/curries-and-kababs/singleDisplayImg_1.jpg",
      singleDisplayImg_2:
        "/assets/pages/work/every-works-data/curries-and-kababs/singleDisplayImg_2.jpg",
    },
  },
  {
    tags: ["branding", "packaging"],
    img: "/assets/pages/work/featured-image-kumaon-organics.jpg",
    title: "Kumaon Organics",
    urlLink: "/view-work",
  },
  {
    tags: ["branding", "communication"],
    img: "/assets/pages/work/featured-image-remark.jpg",
    title: "Remark",
    urlLink: "/view-work",
  },
];

export default allWorkItems;
