import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import InterestedForm from "../forms/InterestedForm";
import StartAProjectForm from "../forms/StartAProjectForm";
import Scrollbar from "smooth-scrollbar";
import animationObserver from "../Utilities/library/animationObserver";

const Contact = () => {
  React.useEffect(() => {
    animationObserver();
  }, []);

  // // scroll to top
  // React.useEffect(() => {
  //   const scrollbar = Scrollbar.init(
  //     document.querySelector("[data-custom-smooth-scroll]")
  //   );

  //   scrollbar["scrollTo"](0, 0);
  // }, []);

  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabButtonClick = (e) => {
    const { name } = e.target;

    const tabItems = document.querySelectorAll(".tabs .tab-item");

    tabItems.forEach((item) => {
      item.classList.add("display-out");
      document.querySelector(`.tabs #${name}`).classList.remove("display-out");
    });
  };

  var sliderSettings = {
    infinite: true,
    speed: 1500,
    fade: false,
    cssEase: "ease",
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id="contact">
      {/* <!-- hero area --> */}
      <article title="hero slider" className="hero hero-slider-grid-container">
        <div className="txt-container custom-hero-heading-container">
          <h1>Let’s design the next big thing together!</h1>
        </div>

        <Slider {...sliderSettings} className="hero-slider-container">
          <div className="slider-item slider-item-1 img-container">
            <img
              src="/assets/contact-hero-slider-2.webp"
              alt="contact slider image"
              className="contact-hero"
            />
          </div>
          <div className="slider-item slider-item-2 img-container">
            <img
              src="/assets/contact-hero-slider-1.webp"
              alt="contact slider image"
              className="contact-hero"
            />
          </div>
        </Slider>
      </article>

      {/* <!-- tabs and form --> */}
      <article title="tabs" className="tabs">
        <div className="tab">
          <button
            title="project"
            onClick={handleTabButtonClick}
            name="start-project"
            autoFocus
          >
            Start a Project
          </button>
          <button title="join" onClick={handleTabButtonClick} name="join-team">
            Join our team
          </button>
        </div>

        <div id="start-project" className="tab-item start-project">
          <StartAProjectForm />
        </div>

        {/* <!-- join-team --> */}
        <div id="join-team" className="tab-item join-team display-out">
          <div className="rich-txt">
            <p>
              Ranked among the Top User Experience &#40;UX&#41; Agencies,
              Onething aims to design a better world, one day at a time. We
              believe that if we commit ourselves to being the best at one
              thing, every single day, there is no looking back. The one thing
              that we live for is - designing performance driven solutions.
            </p>
            <p>
              We've worked with clients from different domains across the globe
              ranging from startups to fortune 500 companies. Few of our clients
              include Coca Cola, HT Media, Swiggy, Airtel, Hero Electronix.
            </p>
          </div>

          <div className="intersted">
            <h2>Intrested</h2>
            <InterestedForm />
          </div>

          <div className="jobs-container">
            <h2>Join Us</h2>
            <p>Current openings</p>

            <div className="jobs">
              <div className="job-item job-item-3">
                <div className="txt-container">
                  <h3 className="job-title">Motion Graphic Designer</h3>
                </div>
                <div className="link-container">
                  <a
                    href="https://www.linkedin.com/jobs/view/2532720119/?refId=BRz4mEYlZTfmMTjHEj0O4Q%3D%3D&trackingId=YAQ5Ug5tEaoQfRjYV3eMgA%3D%3D"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Job
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="contact-info-container">
        <div className="info-card">
          <div className="grid-container">
            <div className="address-area">
              <div className="icon-container">
                <img src="/assets/location.svg" alt="location icon" />
              </div>

              <strong>Delhi</strong>
              <br />
              <br />
              <address>
                B68/2-B, 3rd Floor, <br />
                Nariana Industrial <br />
                Area Phase-2, <br />
                New Delhi, Delhi 110028
              </address>
            </div>

            <div className="social-links-area">
              <div>
                <a href="mailto:hello@sociomonkey.com">
                  <div className="icon-container">
                    <img src="/assets/mail.svg" alt="mail icon" />
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{ color: "red", fontSize: "1.2rem" }}
                  href="mailto:hello@sociomonkey.com"
                >
                  hello@sociomonkey.com
                </a>
              </div>

              <div className="social-links-container">
                <div className="social-link-item">
                  <a
                    title="facebook"
                    href="https://www.facebook.com/sociomonkey"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="facebook" src="/assets/facebook.svg" />
                  </a>
                </div>
                <div className="social-link-item">
                  <a
                    title="instagram"
                    href="https://www.instagram.com/socio.monkey/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="instagram" src="/assets/instagram.svg" />
                  </a>
                </div>
                <div className="social-link-item">
                  <a
                    title="linkedin"
                    href="https://www.linkedin.com/company/sociomonkey/mycompany/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="linkedin" src="/assets/linkedin.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
