import React from "react";
import "../../../Css/ham.css";

function HamIcon({ data }) {
  const { handleHamClick, hamMenuRef } = data;

  return (
    <div className="ham-icon-container">
      <button
        ref={hamMenuRef}
        className="ham-menu"
        onClick={handleHamClick}
        aria-label="Main Menu"
      >
        <img src="/assets/menu.png" />
        <img src="/assets/menu-cross.png" />
      </button>
    </div>
  );
}

export default HamIcon;
