import React, { useState, useEffect } from "react";
import { StartAProjectFormContext } from "../Pages/Contact";
import sendFormData from "../Utilities/library/sendFormData";

const StartAProjectForm = () => {
  const [contactFormData, setContactFormData] = useState({
    formType: {
      value: "Start A Project Form (Sociomonkey.com)",
      name: "From Type",
    },
    name: {
      value: "",
      name: "name",
      required: true,
      placeholder: "What's your name",
    },
    message: {
      value: "",
      name: "message",
      required: false,
      placeholder: "Your message",
    },
    email: {
      value: "",
      name: "email",
      required: true,
      placeholder: "Your email address",
    },
    phone: {
      value: "",
      name: "phone",
      required: true,
      placeholder: "Your phone number",
    },
    company: {
      value: "",
      name: "company",
      required: false,
      placeholder: "Your company name (optional)",
    },
    howCanWeHelpData: {
      value: "",
      required: false,
      name: "How can we help",
      label: "What are your key challenges?",
    },
  });

  const handleFromChange = (e) => {
    const { name, value, type } = e.target;

    setContactFormData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], name, value },
    }));
  };

  const [HowCanWeHelpData, setHowCanWeHelpData] = useState([
    {
      checked: false,
      value: "Brand Identity Design",
      id: 1,
    },
    {
      checked: false,
      value: "Social Media Marketing",
      id: 2,
    },
    {
      checked: false,
      value: "Media Planning",
      id: 3,
    },
    {
      checked: false,
      value: "Collateral Design",
      id: 4,
    },
    {
      checked: false,
      value: "Website Design and Development",
      id: 5,
    },
    {
      checked: false,
      value: "Fulfillment Solutions",
      id: 7,
    },
    {
      checked: false,
      value: "ECommerce Solutions",
      id: 8,
    },
    {
      checked: false,
      value: "Video Production",
      id: 9,
    },
    {
      checked: false,
      value: "Influencer Marketing",
      id: 10,
    },
    {
      checked: false,
      value: "Public Relations",
      id: 12,
    },
    {
      checked: false,
      value: "QRM and Listing",
      id: 13,
    },
    {
      checked: false,
      value: "Search Engine Optimization",
      id: 14,
    },
  ]);

  useEffect(() => {
    let newData = HowCanWeHelpData.filter(({ checked }) => checked).map(
      ({ value }) => value
    );

    setContactFormData((prevObj) => ({
      ...prevObj,
      howCanWeHelpData: { ...prevObj["howCanWeHelpData"], value: newData },
    }));
  }, [HowCanWeHelpData]);

  const [message, setMessage] = useState("");

  const handleCheckboxClick = (e) => {
    const { id } = e.target;

    setHowCanWeHelpData((prevObj) => {
      const newData = prevObj.map((data) => {
        if (Number(id) === Number(data.id))
          return { ...data, checked: !data.checked };
        else return data;
      });

      return [...newData];
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(contactFormData);
    setMessage(() => "Connecting to the server..");
    sendFormData(contactFormData, setMessage);
  };

  return (
    <form
      id="project-form"
      name="project-form"
      className="contact-forms"
      method="POST"
      onSubmit={handleSubmit}
    >
      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay="0"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["name"]["required"]}
          placeholder={contactFormData["name"]["placeholder"]}
          type="text"
          id={contactFormData["name"]["name"]}
          name={contactFormData["name"]["name"]}
          title={contactFormData["name"]["name"]}
          value={contactFormData["name"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".05"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["email"]["required"]}
          placeholder={contactFormData["email"]["placeholder"]}
          type="email"
          id={contactFormData["email"]["name"]}
          name={contactFormData["email"]["name"]}
          title={contactFormData["email"]["name"]}
          value={contactFormData["email"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".1"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["phone"]["required"]}
          placeholder={contactFormData["phone"]["placeholder"]}
          type="tel"
          id={contactFormData["phone"]["name"]}
          name={contactFormData["phone"]["name"]}
          title={contactFormData["phone"]["name"]}
          value={contactFormData["phone"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".15"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["company"]["required"]}
          placeholder={contactFormData["company"]["placeholder"]}
          type="text"
          id={contactFormData["company"]["name"]}
          name={contactFormData["company"]["name"]}
          title={contactFormData["company"]["name"]}
          value={contactFormData["company"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div
        className="checkboxes-container"
        id="I want"
        data-animname="translate-x-reset"
        data-delay=".25"
        data-animtiming="1"
        data-onetime="true"
      >
        <p style={{ fontSize: "1.2rem" }}> How can we help ?</p>
        <br />

        {HowCanWeHelpData?.map(({ checked, id, value }) => (
          <div className="check-box-item" key={id}>
            <label>
              <input
                onChange={handleCheckboxClick}
                id={id}
                name="keyChallanges"
                type="checkbox"
                checked={checked}
                required={contactFormData["howCanWeHelpData"]["required"]}
                value={value}
              />
              {value}
            </label>
          </div>
        ))}
      </div>

      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".2"
        data-animtiming="1"
        data-onetime="true"
      >
        <label htmlFor="message"></label>
        <textarea
          required={contactFormData["message"]["required"]}
          placeholder={contactFormData["message"]["placeholder"]}
          id={contactFormData["message"]["name"]}
          name={contactFormData["message"]["name"]}
          title={contactFormData["message"]["name"]}
          value={contactFormData["message"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div style={{ opacity: "1", transform: "none" }} className="form-item">
        <p className="message">{message}</p>
      </div>

      <div
        className="form-item-submit"
        data-animname="translate-x-reset"
        data-delay=".3"
        data-animtiming="1"
        data-onetime="true"
      >
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default StartAProjectForm;
