import React, { useEffect } from "react";
import Header from "./Components/Header";
import Main from "./Components/Main";
import Footer from "./Components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Css/custom-slick-rules.css";
import "./Css/animations.css";
import "./Css/utility.css";
import "./Css/index.css";
import Scrollbar from "smooth-scrollbar";

function App() {
  // useEffect(() => {
  //   Scrollbar.init(document.querySelector("[data-custom-smooth-scroll]"), {
  //     damping: 0.1,
  //   });
  // }, []);

  return (
    <>
      <Header />
      {/* <div data-custom-smooth-scroll=""> */}
        <Main />
        <Footer />
      {/* </div> */}
    </>
  );
}

export default App;
