import React from "react";
import animationObserver from "../Utilities/library/animationObserver";
import ArticleElement from "../Utilities/mini-comps/ArticleElement";
import Scrollbar from "smooth-scrollbar";

const About = () => {
  React.useEffect(() => {
    animationObserver();
  }, []);

  // // scroll to top
  // React.useEffect(() => {
  //   const scrollbar = Scrollbar.init(
  //     document.querySelector("[data-custom-smooth-scroll]")
  //   );

  //   scrollbar["scrollTo"](0, 0);
  // }, []);

  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="about-main">
      {/* <!-- about us section --> */}
      <section id="about" title="about">
        {/* <!-- hero area --> */}
        <article className="hero hero-grid-container">
          <div className="txt-container custom-hero-heading-container">
            <h1>
              Driven by teamwork, digital solutions & the people that use them.
            </h1>
          </div>
          <div className="img-container">
            <img
              src="/assets/about-hero.webp"
              alt="about hero image"
              className="about-hero"
            />
          </div>
        </article>

        <h1 className="custom-small-heading">Our Team</h1>

        {/* <!-- team photo --> */}
        <article title="team" className="team img-with-border">
          <div className="img-container">
            <img src="/assets/photo-team.webp" alt="" />
          </div>
        </article>

        {/* <!-- services section --> */}
        <section className="services" id="services" title="services">
          <div className="rich-quote-container-container"></div>

          <h1 className="what custom-small-heading">What we do</h1>

          {/* <!-- article digital-stratagey --> */}
          <ArticleElement
            data={{
              title: "digital stratagey",
              textDirection: "left",
              className: "grid-container digital-stratagey",
              heading: "Digital Strategy",
              content:
                "To set your business on the fast-track to success, our team of digital mixologists use technology, strategy and human brilliance to help you build your digital presence.",
              quote:
                "“MARKETING CAMPAIGNS BUILT TO DOMINATE THE DIGITAL PLATFORMS”",
              img: "/assets/services-digital-strategy.webp",
            }}
          />

          {/* <!-- article branding --> */}
          <ArticleElement
            data={{
              title: "Branding",
              textDirection: "right",
              className: "grid-container digital-stratagey",
              heading: "Branding",
              content:
                "We create engaging and expressive brands bringing them alive on digital spaces by building impressive product images for a larger customer base.",
              quote:
                "“BRANDING REFLECTS A BOLD STATEMENT THAT YOUR BUSINESS MAKES”",
              img: "/assets/services-branding.webp",
            }}
          />

          {/* <!-- article UX --> */}
          <ArticleElement
            data={{
              title: "ux",
              textDirection: "left",
              className: "grid-container ux",
              heading: "User Experience",
              content:
                "We are a team of designers, creative thinkers and strategists working together to create a smooth user experience for your digital storefront.",
              quote:
                "“USER EXPERIENCE DESIGN IS YOUR BRAND’S SILENT AMBASSADOR”",
              img: "/assets/services-ux.webp",
            }}
          />

          {/* <!-- article product development --> */}
          <ArticleElement
            data={{
              title: "product development",
              textDirection: "right",
              className: "grid-container porduct-development",
              heading: "Product Development",
              content:
                "Onboard the journey of your product from conception to diverse market segments.",
              quote: "“BRINGING ALIVE YOUR IDEA OF A PRODUCT”",
              img: "/assets/services-product-development.webp",
            }}
          />

          {/* <!-- article media-production --> */}
          <ArticleElement
            data={{
              title: "media production",
              textDirection: "left",
              className: "grid-container media-production",
              heading: "Media Platform",
              content:
                "We promise to provide brilliantly accurate decisions required to choose from the rush of puzzling digital media platform options available today.",
              quote: "“TAILOR-MADE SOLUTIONS FOR YOUR MEDIA PLATFORM NEEDS”",
              img: "/assets/services-media-production.webp",
            }}
          />

          {/* <!-- article digital marketing --> */}
          <ArticleElement
            data={{
              title: "digital marketing",
              textDirection: "right",
              className: "grid-container digital-marketing",
              heading: "Digital Marketing",
              content:
                "We help businesses grow digitally and retain the right customers using appropriate digital marketing tools.",
              quote:
                "“DELIVERING CREATIVITY AND STRATEGY THROUGH DIGITAL MEDIA PLATFORMS”",
              img: "/assets/services-digital-marketing.webp",
            }}
          />

          {/* <!-- ECommerce Solutions --> */}
          <ArticleElement
            data={{
              title: "ECommerce Solutions",
              textDirection: "left",
              className: "grid-container ecom-solutions",
              heading: "ECommerce Solutions",
              content:
                "Our experienced team of ecommerce specialists help you find the perfect platform for your online business. We also specialize in ecommerce website design, development and marketing  enabling you to gain an edge over the competition.",
              quote: "“ALL E-COMMERCE SOLUTIONS UNDER ONE ROOF”",
              img: "/assets/pages/about/ecom-solutions.jpg",
            }}
          />

          {/* <!-- Fulfillment Solutions --> */}
          <ArticleElement
            data={{
              title: "Fulfillment Solutions",
              textDirection: "right",
              className: "grid-container fulfiltment-solutions",
              heading: "Fulfillment Solutions",
              content:
                "If you are planning to scale up your business, it might be a wise idea to outsource your  warehousing and fulfillment needs. We promise to be your dedicated fulfillment partner while letting you focus on expanding your core business.",
              quote: "“360 solution for storage and fulfillment needs”",
              img: "/assets/pages/about/fulfilment-solutions.jpg",
            }}
          />
        </section>

        <div className="rich-quote-container-container">
          <blockquote>
            With our digital products we deliver experiences that create a
            powerful impact and productive results for every client who walks in
            our doors. You can be one of them!
          </blockquote>
        </div>
      </section>
    </div>
  );
};

export default About;
