import React, { useRef, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { hideOnScroll } from "./Utilities/library/hideOnScroll";
import HamIcon from "./Utilities/mini-comps/HamIcon";

const Header = () => {
  const headerRef = useRef(undefined);
  const hamMenuRef = useRef(null);

  const handleHamClick = (e) => {
    if (!e) return;

    headerRef.current.classList.toggle("translate-out");

    if (headerRef.current.classList.contains("translate-out")) {
      hamMenuRef.current.classList.remove("open");
    } else {
      hamMenuRef.current.classList.add("open");
    }
  };

  const animateHamIcon = () => {
    if (headerRef.current.classList.contains("translate-out")) {
      hamMenuRef.current.classList.remove("open");
    } else {
      hamMenuRef.current.classList.add("open");
    }
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 650) {
      headerRef.current.classList.add("translate-out");
    }

    handleHamClick();
    animateHamIcon();
  };

  useEffect(() => {
    if (window.innerWidth <= 650) {
      headerRef.current.classList.add("translate-out");
    } else {
      hideOnScroll(headerRef.current);
    }
  }, []);

  window.onscroll = () => {
    if (window.pageYOffset > 450)
      headerRef.current.classList.add("header-with-bg");
    else headerRef.current.classList.remove("header-with-bg");
  };

  return (
    <>
      <div className="mobile-header">
        <div className="nav-link-item">
          <Link onClick={handleLinkClick} className="logo-container" to="/">
            <img src="/assets/logo.png" alt="logo" />
          </Link>
        </div>

        <HamIcon data={{ handleHamClick, hamMenuRef }} />
      </div>

      {/* <!-- header --> */}
      <header className="reveal-header" id="header" ref={headerRef}>
        <nav>
          <Link to="/">
            <div className="logo-container">
              <img alt="logo" src="/assets/logo.png" />
            </div>
          </Link>
          <ul>
            <li className="nav-link-home">
              <Link onClick={handleLinkClick} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to="/about">
                About
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to="/contact">
                Contact
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to="/work">
                Work
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
