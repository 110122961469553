import React from "react";

const ArticleElement = ({ data }) => {
  const { title, className, heading, content, quote, img, textDirection } =
    data;

  return (
    <article
      title={title}
      className={`grid-parent grid-parent-txt-${textDirection}`}
    >
      <div className={`${className} grid-container-txt-${textDirection}`}>
        <div
          className="txt-container"
          data-animname="translate-x-reset"
          data-animtiming="1"
          data-onetime="true"
          data-delay=".1"
        >
          <h2>{heading}</h2>

          <p>{content}</p>

          {quote && (
            <div className="quote-container">
              <div className="quote">{quote}</div>
            </div>
          )}
        </div>

        <div
          className="img-container"
          data-animname="translate-y-reset"
          data-animtiming="1"
          data-onetime="true"
          data-delay=".3"
        >
          <img alt={img} src={img} />
        </div>
      </div>
    </article>
  );
};

export default ArticleElement;
