import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const Footer = () => {
  return (
    <footer id="footer">
      <p className="email">
        <a style={{ color: "inherit" }} href="mailto:hello@sociomonkey.com">
          hello@sociomonkey.com
        </a>
      </p>
      <div className="info-container">
        <div className="address-container">
          <h3>India</h3>

          <address>New Delhi</address>

          <div className="social-links-container">
            <div className="social-link-item">
              <a
                title="facebook"
                href="https://www.facebook.com/sociomonkey"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img alt="facebook" src="/assets/facebook.svg" />
              </a>
            </div>
            <div className="social-link-item">
              <a
                title="instagram"
                href="https://www.instagram.com/socio.monkey/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img alt="instagram" src="/assets/instagram.svg" />
              </a>
            </div>
            <div className="social-link-item">
              <a
                title="linkedin"
                href="https://www.linkedin.com/company/sociomonkey/mycompany/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img alt="linkedin" src="/assets/linkedin.svg" />
              </a>
            </div>
          </div>
        </div>

        <div className="links-container">
          <div className="link-set link-set-1">
            <Link to="/">Home</Link>
            <Link to="/">Work</Link>
            <Link to="/">Expertise</Link>
            <Link to="/about">About</Link>
          </div>

          <div className="link-set link-set-2">
            <Link to="/">Awards</Link>
            <Link to="/">Brands</Link>
            <Link to="/contact#join-team">Careers</Link>
            <Link to="/">Inquiries</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
