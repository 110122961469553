import React from "react";
import { Route, Switch } from "react-router-dom";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import SelectedWork from "./Pages/SelectedWork";
import Home from "./Pages/Home";
import NotFoundPage from "./Pages/NotFoundPage";
import Work from "./Pages/Work";

const Main = () => {
  return (
    <main>
      <Switch>
        <Route exact path="/view-work" component={SelectedWork} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route exact path="/work" component={Work} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/index.html" component={Home} />
        <Route exact path="/" component={Home} />
        <Route exact path="/404" component={NotFoundPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </main>
  );
};

export default Main;
