import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import ResponsiveDisplayImg from "../Utilities/mini-comps/ResponsiveDisplayImg";
import { useHistory } from "react-router-dom";

const SelectedWork = () => {
  const [currentWorkData, setCurrentWorkData] = useState({});
  let history = useHistory();

  useEffect(() => {
    const data = sessionStorage.getItem("currentWorkData");

    if (data === "undefined") {
      history.push("/404");
    } else {
      setCurrentWorkData(() => JSON.parse(data));
    }
  }, []);

  const {
    heroImg,
    imgWithTextGrid_1_Img,
    imgWithTextGrid_1_Txt,
    imgWithTextGrid_2_Img,
    imgWithTextGrid_2_Txt,
    displayImg_Group_1_Img,
    displayImg_Group_1_Txt,
    displayImg_Group_2_Img,
    displayImg_Group_2_Txt,
    displayImg_Grid_Img_1,
    displayImg_Grid_Img_2,
    singleDisplayImg_1,
    singleDisplayImg_2,
  } = currentWorkData;

  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="selected-work-page" id="selected-work-page">
      <div className="hero">
        <ResponsiveDisplayImg data={{ img: heroImg }} />
      </div>

      <div className="two-column-grid img-with-text-container">
        <ResponsiveDisplayImg data={{ img: imgWithTextGrid_1_Img }} />
        <div className="txt-container txt-center">
          {ReactHtmlParser(imgWithTextGrid_1_Txt)}
        </div>
      </div>

      <div className="dispay-img-txt-group">
        <ResponsiveDisplayImg data={{ img: displayImg_Group_1_Img }} />
        <p className="rte txt-center">
          {ReactHtmlParser(displayImg_Group_1_Txt)}
        </p>
      </div>

      <div className="display-img-grid">
        <ResponsiveDisplayImg data={{ img: displayImg_Grid_Img_1 }} />
        <ResponsiveDisplayImg data={{ img: displayImg_Grid_Img_2 }} />
      </div>

      <div className="single-dispay-img">
        <ResponsiveDisplayImg data={{ img: singleDisplayImg_1 }} />
      </div>

      <div className="two-column-grid img-with-text-container">
        <ResponsiveDisplayImg data={{ img: imgWithTextGrid_2_Img }} />
        <div className="txt-container txt-center">
          {ReactHtmlParser(imgWithTextGrid_2_Txt)}
        </div>
      </div>

      <div className="dispay-img-txt-group">
        <ResponsiveDisplayImg data={{ img: displayImg_Group_2_Img }} />
        <p className="rte txt-center">
          {ReactHtmlParser(displayImg_Group_2_Txt)}
        </p>
      </div>

      <div className="single-dispay-img">
        <ResponsiveDisplayImg data={{ img: singleDisplayImg_2 }} />
      </div>
    </div>
  );
};

export default SelectedWork;
