import React, { useEffect, useState, useRef } from "react";
import allWorkItems from "../Utilities/library/allWorkItems";
import ResponsiveDisplayImg from "../Utilities/mini-comps/ResponsiveDisplayImg";
import TagButtonItem from "../Utilities/mini-comps/TagButtonItem";
import WorkItem from "../Utilities/mini-comps/WorkItem";

const Work = () => {
  const [allTags, setAllTags] = useState([
    "branding",
    "packaging",
    "communication",
    "video production",
    "ecommerce",
    "identity",
  ]);

  const [currentTags, setCurrentTags] = useState([...allTags]);

  const [currentWorkItems, setCurrentWorkItems] = useState([...allWorkItems]);

  const tagButtonsContainerRef = useRef(null);

  const updateCurrentTags = () => {
    const { id } = tagButtonsContainerRef.current;
    const elements = document.querySelectorAll(`#${id} .tag-item .btn`);
    const activeTags = [...elements]
      .filter((element) => element.classList.contains("active"))
      .map(({ name }) => name);

    setCurrentTags(() => activeTags);
  };

  const handleTagClick = (e) => {
    const { name } = e.target;

    console.log(name);
    e.target.classList.toggle("active");

    updateCurrentTags();
  };

  // fallback for no active tag
  useEffect(() => {
    if (!currentTags.length) setCurrentTags([...allTags]);
  }, [currentTags]);

  //upadate the current works
  useEffect(() => {
    setCurrentWorkItems(() =>
      allWorkItems.filter((workItem) =>
        currentTags.some((currentTag) =>
          workItem.tags.some((workItemTag) => workItemTag === currentTag)
        )
      )
    );
  }, [currentTags]);

  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="work">
      <ResponsiveDisplayImg
        data={{
          img: "/assets/pages/work/hero.jpg",
          mobImg: "/assets/pages/work/hero-mob.jpg",
        }}
      />

      {/* //render the buttons  */}

      <div
        id="tags-buttons-container"
        className="tags-buttons-container"
        ref={tagButtonsContainerRef}
      >
        {allTags?.map((tag, index) => (
          <TagButtonItem data={{ tag, handleTagClick }} key={index} />
        ))}
      </div>

      {/* reneder the work items */}

      <div className="works-container">
        {currentWorkItems?.map((data, index) => (
          <WorkItem data={{ ...data }} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Work;
