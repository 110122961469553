import React from "react";
import animationObserver from "../Utilities/library/animationObserver";
import Slider from "react-slick";
import Scrollbar from "smooth-scrollbar";
import ArticleElement from "../Utilities/mini-comps/ArticleElement";
import Parallax from "../Utilities/Parallax";
import { HashLink as Link } from "react-router-hash-link";

const Home = () => {
  React.useEffect(() => {
    animationObserver();
  }, []);

  // // scroll to top
  // React.useEffect(() => {
  //   const scrollbar = Scrollbar.init(
  //     document.querySelector("[data-custom-smooth-scroll]")
  //   );

  //   scrollbar["scrollTo"](0, 0);
  // }, []);

  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var sliderSettings = {
    infinite: true,
    speed: 1500,
    fade: false,
    dots: true,
    cssEase: "ease",
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id="home">
      {/* <!-- hero section --> */}
      <section id="hero" title="hero">
        <Slider {...sliderSettings} className="hero-slider-container">
          {/* <!-- hero-slider-area --> */}

          <div className="slider-item slider-item-2">
            <div className="img-container">
              <picture>
                <source
                  media="(max-width: 750px)"
                  srcSet="./assets/img-hero-slider-1-mob.png"
                />
                <img alt="slider image" src="/assets/img-hero-slider-1.webp" />
              </picture>
            </div>
            <div className="txt-container custom-hero-heading-container">
              <h2>Revolutionizing the food experience!</h2>
            </div>
          </div>
          <div className="slider-item slider-item-3">
            <div className="img-container">
              <picture>
                <source
                  media="(max-width: 750px)"
                  srcSet="./assets/img-hero-slider-2-mob.png"
                />
                <img alt="slider image" src="/assets/img-hero-slider-2.webp" />
              </picture>
            </div>
            <div className="txt-container custom-hero-heading-container">
              <h2>Bringing the ultimate luxury to the web.</h2>
            </div>
          </div>
        </Slider>
      </section>

      {/* <!-- commerce --> */}
      <ArticleElement
        data={{
          title: "commerce",
          textDirection: "left",
          className: "grid-container commerce",
          heading: "Commerce",
          content:
            "If you are aiming for your brand growth, we have you covered. Mixing up technology and strategies together and delivering an ideal consumer journey. We provide you the cutting-edge solutions from the starting to the outcomes.",
          img: "/assets/pages/home/commerce.jpg",
        }}
      />

      {/* <!-- Insights --> */}
      <ArticleElement
        data={{
          title: "insights",
          textDirection: "right",
          className: "grid-container insights",
          heading: "Insights",
          content:
            "We are motivated to consult you with the best on-table strategies which we establish & communicate through commercial channels. We help you reach out marketplaces: all online, offline and social while building stronger relationships to hack the code to your success.",
          img: "/assets/pages/home/insights.jpg",
        }}
      />

      {/* <!-- Experience --> */}
      <ArticleElement
        data={{
          title: "experience",
          textDirection: "left",
          className: "grid-container experience",
          heading: "Experience",
          content:
            "We work and deliver ideas and solutions that create an everlasting impact to make an identity and cut through the digital noise. We are your friendly companions to take you through a seamless experience and share a download of our mental catalogue for a better future.",
          img: "/assets/pages/home/experience.jpg",
        }}
      />

      {/* <!-- Creativity --> */}
      <ArticleElement
        data={{
          title: "creativity",
          textDirection: "right",
          className: "grid-container creativity",
          heading: "Creativity",
          content:
            "Everytime a brand tries to make its identity in the digital world, we put our prolific hats on to bring inspiration to the table. We are the creative buddies to elevate your brand, lead it with purpose towards its ultimate goal.",
          img: "/assets/pages/home/creativity.jpg",
        }}
      />

      {/* <!-- peoples section --> */}
      <section id="peoples" title="peoples">
        {/* <!-- peoples photo --> */}
        <article className="img-with-border" title="peoples">
          <div className="img-container">
            <p className="what custom-small-heading">
              We do awesome things with awesome people
            </p>
            <img alt="" src="/assets/photo-people.webp" />
          </div>
        </article>
      </section>

      <section className="parallax-section">
        <h2 className="parallax-heading">Some of our work</h2>

        <Parallax
          data={[
            {
              img: "/assets/pages/home/parallax-images/curries-and-kababs.jpg",
              heading: "CURRIES AND KABABS",
              txt: "BRANDING",
            },
            {
              img: "/assets/pages/home/parallax-images/nostra.jpg",
              heading: "NOSTRA",
              txt: "BRANDING AND PACKAGING",
            },
            {
              img: "/assets/pages/home/parallax-images/kumaon-organics.jpg",
              heading: "KUMAON ORGANICS",
              txt: "BRANDING AND PACKAGING",
            },
            {
              img: "/assets/pages/home/parallax-images/waos.jpg",
              heading: "WAOS",
              txt: "SOCIAL MEDIA",
            },
          ]}
        />

        <div className="home-end-buttons">
          <Link className="btn" to="/">
            Check out more
          </Link>
          <Link className="btn" to="/contact">
            Contact US
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
