import React, { useEffect } from "react";
import simpleParallax from "simple-parallax-js";

const Parallax = ({ data }) => {
  useEffect(() => {
    const images = document.querySelectorAll("[data-parallax-img]");
    new simpleParallax(images, {
      scale: 1.7,
      delay: 0.4,
      transition: "cubic-bezier(0,0,0,1)",
      overflow: false,
      customWrapper: ".parallax-item",
    });
  }, []);

  return (
    <div className="parallax-container">
      {data?.map(({ img, heading, txt }, index) => (
        <div className={`parallax-item parallax-item-${++index}`} key={index}>
          <img src={img} alt={img} data-parallax-img />
          <div className="txt-container">
            <h2>{heading}</h2>
            <p className="rte">{txt}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Parallax;
