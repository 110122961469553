import React, { useState } from "react";
import sendFormData from "../Utilities/library/sendFormData";

const InterestedForm = () => {
  const [contactFormData, setContactFormData] = useState({
    formType: {
      value: "Interested Form (Sociomonkey.com)",
      name: "From Type",
    },
    name: {
      value: "",
      name: "name",
      required: true,
      placeholder: "What's your name",
    },
    email: {
      value: "",
      name: "email",
      required: true,
      placeholder: "Your email address",
    },
    phone: {
      value: "",
      name: "phone",
      required: true,
      placeholder: "Your phone number",
    },
    linkedIn: {
      value: "",
      name: "linkedIn",
      required: false,
      placeholder: "Your linkedin profile link",
    },
    portfolio: {
      value: "",
      name: "portfolio",
      required: false,
      placeholder: "Your portfolio link",
    },
  });

  const handleFromChange = (e) => {
    const { name, value, type } = e.target;

    setContactFormData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], name, value },
    }));
  };

  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(contactFormData);
    setMessage(() => "Connecting to the server..");
    sendFormData(contactFormData, setMessage);
  };

  return (
    <form
      id="intersted-form"
      name="intersted-form"
      className="contact-forms"
      method="POST"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".25"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["name"]["required"]}
          placeholder={contactFormData["name"]["placeholder"]}
          type="text"
          id={contactFormData["name"]["name"]}
          name={contactFormData["name"]["name"]}
          title={contactFormData["name"]["name"]}
          value={contactFormData["name"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".3"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["email"]["required"]}
          placeholder={contactFormData["email"]["placeholder"]}
          type="email"
          id={contactFormData["email"]["name"]}
          name={contactFormData["email"]["name"]}
          title={contactFormData["email"]["name"]}
          value={contactFormData["email"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".35"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["phone"]["required"]}
          placeholder={contactFormData["phone"]["placeholder"]}
          type="tel"
          id={contactFormData["phone"]["name"]}
          name={contactFormData["phone"]["name"]}
          title={contactFormData["phone"]["name"]}
          value={contactFormData["phone"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".4"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["linkedIn"]["required"]}
          placeholder={contactFormData["linkedIn"]["placeholder"]}
          type="url"
          id={contactFormData["linkedIn"]["name"]}
          name={contactFormData["linkedIn"]["name"]}
          title={contactFormData["linkedIn"]["name"]}
          value={contactFormData["linkedIn"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div
        className="form-item"
        data-animname="translate-x-reset"
        data-delay=".45"
        data-animtiming="1"
        data-onetime="true"
      >
        <input
          required={contactFormData["portfolio"]["required"]}
          placeholder={contactFormData["portfolio"]["placeholder"]}
          type="url"
          id={contactFormData["portfolio"]["name"]}
          name={contactFormData["portfolio"]["name"]}
          title={contactFormData["portfolio"]["name"]}
          value={contactFormData["portfolio"]["value"]}
          onChange={handleFromChange}
        />
      </div>

      <div style={{ opacity: 1, transform: "none" }} className="form-item">
        <p className="message">{message}</p>
      </div>

      <div
        className="form-item-submit"
        data-animname="translate-x-reset"
        data-delay=".5"
        data-animtiming="1"
        data-onetime="true"
      >
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default InterestedForm;
