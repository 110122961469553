import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import ResponsiveDisplayImg from "./ResponsiveDisplayImg";

const WorkItem = ({ data }) => {
  const { img, title, tags, urlLink, detailedWorkData } = data;

  const handleWorkItemClick = () => {
      sessionStorage.setItem(
        "currentWorkData",
        JSON.stringify(detailedWorkData)
      );
  };

  return (
    <Link to={urlLink} className="work-item" onClick={handleWorkItemClick}>
      <ResponsiveDisplayImg data={{ img }} />
      <div className="title">{title}</div>
      <div className="tags">{tags.join(", ")}</div>
    </Link>
  );
};

export default WorkItem;
